import {
  breakpointMap,
  type ImageTemplateProps,
  type sizesObject,
} from '@/types/ImgSizes';

export const buildSizesString = ({
  defaultSize,
  sizeConfig,
}: ImageTemplateProps) => {
  let sizesString = '';
  const sizesAscending = Object.entries(sizeConfig).sort(sortHandler);

  // We want our `sizes` string to behave like our tailwind classes
  // i.e, `md:m-auto` should apply `m-auto` when the breakpoint is md or above
  // Browser will use the first matching media query from the sizes string,
  // so we need to build up the string using `max-width`
  sizesAscending.forEach(([bp], i) => {
    const breakpointPx = breakpointMap[bp as keyof sizesObject];
    if (i === 0) {
      // use default image size for first breakpoint
      sizesString = `(max-width: ${breakpointPx}) ${defaultSize}`;
    } else {
      // Otherwise, use the previous image size for this breakpoint
      const previousSize = sizesAscending[i - 1][1];
      sizesString = `${sizesString}, (max-width: ${breakpointPx}) ${previousSize}`;
    }
  });

  // The largest BP size we have specified will apply if none of the preceding media queries match
  const largestSize = sizesAscending[sizesAscending.length - 1][1];
  sizesString = `${sizesString}, ${largestSize}`;

  return sizesString;
};

const sortHandler = (a: [string, string], b: [string, string]) => {
  const aSize = breakpointMap[a[0] as keyof sizesObject].replace('px', '');
  const bSize = breakpointMap[b[0] as keyof sizesObject].replace('px', '');

  return Number(aSize) - Number(bSize);
};
