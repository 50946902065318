'use client';

import { forwardRef } from 'react';
// eslint-disable-next-line no-restricted-imports
import _NextLink from 'next/link';

import { normalizeUrl } from '@/utils/normalizeUrl';

/**
 * @remarks
 * Aliasing the next/link component allows us to de-conflict the naming collision between our custom <Link> and nextjs' <Link>
 * (which is necessary for our eslint configuration).
 * Additionally it allows us to set default props and normalize URLs.
 */

export const NextLink = forwardRef<
  HTMLAnchorElement,
  React.ComponentProps<typeof _NextLink>
>(({ href, ...props }, ref) => {
  // normalize the href only if it's a string eg (href: "/news/some-news/")
  const cleanedHref =
    typeof href === 'string' ? normalizeUrl({ paths: [href] }) : href;
  return <_NextLink href={cleanedHref} prefetch={false} ref={ref} {...props} />;
});

NextLink.displayName = 'NextLink';
